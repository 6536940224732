import { ReactComponent as NodeIcon } from '../assets/icons/technologies/NodeJS.svg';
import { ReactComponent as HTMLIcon } from '../assets/icons/technologies/HTML.svg';
import { ReactComponent as CSSIcon } from '../assets/icons/technologies/CSS.svg';
import { ReactComponent as JavascriptIcon } from '../assets/icons/technologies/Javascript.svg';
import { ReactComponent as ReactIcon } from '../assets/icons/technologies/React.svg';
import { ReactComponent as NextJSIcon } from '../assets/icons/technologies/NextJS.svg';
import { ReactComponent as SQLIcon } from '../assets/icons/technologies/SQL.svg';
import { ReactComponent as TailwindIcon } from '../assets/icons/technologies/Tailwind.svg';

const className="w-full hover:scale-150 transition-all"
export const techstack = [
    <NodeIcon className={className}/>,
    <HTMLIcon className={className}/>,
    <CSSIcon className={className}/>,
    <JavascriptIcon className={className}/>,
    <ReactIcon className={className}/>,
    <NextJSIcon className={className}/>,
    <SQLIcon className={className}/>,
    <TailwindIcon className={className}/>
]