import React, { useEffect } from "react";
import AccordionItem from "./AccordionItem";
import services from "../utils/services";

export default function Accordion({accordionItems}){
    return (
        <>
            <div className='anim__collapse w-full z[5] lg:w-7/12 my-8 lg:my-0'>
                {
                    services.map(service => {
                        return <AccordionItem
                            id={service.id}
                            title={service.title}
                            description={service.description}
                        />
                    })
                }
            </div>
        </>
    )
}