const navigation = [
    {
        title: "Home",
        href: "/"
    },
    {
        title: "About",
        href: "/about"
    },
    {
        title: "Portfolio",
        href: "/portfolio"
    },
    {
        title: "Contact",
        href: "/contact"
    },
]

export default navigation;