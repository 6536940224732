import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from '../assets/icons/chevron-arrow.svg';
import gsap from "gsap";

export default function AccordionItem({id, title, description}){
    const [open, setOpen] = useState(false)
    const accordionId = `accordion__item__${id}`

    useEffect(() => {
        if(open){
            gsap.to(`#${accordionId}`, {
                height: "auto"
            })
        }
        else{
            gsap.to(`#${accordionId}`, {
                height: 0
            })
        }
    }, [open])

    return <div className="accordion__group border-b border-text">
        <button className='w-full relative cursor-none button__hover__difference accordion__menu z-[8] border-text flex justify-between items-center' onClick={() => setOpen((!open))}>
            <h3 className='text-2xl md:text-4xl py-8 text-start'>{title}</h3>
            <ArrowDown className={`transition-all duration-500 accordion__arrow w-8 h-8 mr-4 ${open?'rotate-180':'rotate-[360deg]'}`} />
        </button>
        <div id={accordionId} className={`overflow-hidden text-2xl h-0`}>
            <div className="m-4 mt-0 text-xl text-text w-10/12">
                {description}
            </div>
        </div>
    </div>
}