import hero from '../assets/img/hero.png'
import ImageWrapper from "../components/ImageWrapper";
import Accordion from "../components/Accordion";
import Spinner from "../components/Spinner";
import TestimonialsCarousel from '../components/TestimonialsCarousel'
import testimonials from "../utils/testimonials";
import TechStack from "../sections/TechStack";
import PortfolioSection from "../sections/PortfolioSection";

export default function Home() {
  return (
    <>
      <div className="w-full flex md:min-h-[100vh] items-center">
        <h2 className='hidden lg:block text-[9rem] 2xl:text-[12rem] absolute text-[#181818] -z-20 top-[10%] left-[5%]'>Welcome</h2>
        <div className="py-0 page__width flex flex-col md:flex-row items-center justify-center">
          <div className="flex w-full md:w-3/5 flex-col items-start">
            <div className="overflow-hidden">
              <h1 className="gsap__reveal text-4xl md:text-6xl">Discover the key to Digital Growth as a service-based business.</h1>
            </div>
            <a href="/contact" className='button__hover__difference block text-brand my-4 text-2xl md:text-3xl'>Get In Touch</a>
          </div>
          <div className="w-3/5 ml-auto md:w-2/5 md:-ml-40 -z-10">
            <ImageWrapper src={hero} alt="Hero" className="w-full object-cover" />
          </div>
        </div>
        <Spinner />
      </div>

      <section id="services">
        <div className='page__width my-[20%]'>
          <div className='flex flex-col lg:flex-row items-center justify-between'>
            <div className='services__left flex flex-col items-start w-full lg:w-5/12'>
              <label className='text-xl md:text-2xl text-text font-semibold'>Services</label>
              <h2 className='font-semibold text-[4rem] leading-[4rem] md:text-[calc(24px+3vw)] md:leading-[1.2] my-4 mb-8 text__stroke'>Digital Brilliance</h2>
              <p className='text-2xl md:text-3xl'>Refine, Expand, and Dominate the digital space with our High-End solutions</p>
            </div>
            <Accordion />
          </div>
        </div>
      </section>

      <PortfolioSection />

      {/* <TechStack /> */}


      <section id="testimonials">
        <TestimonialsCarousel slides={testimonials} />
      </section>
    </>
  )
}