import React, { useEffect } from 'react'
import gsap from "gsap";
import navigation from "../utils/menu";
export default function Menu({route}) {
    let t1 = gsap.timeline({ paused: true });
    const toggle = () => {
        t1.reversed(!t1.reversed())
    }

    useEffect(() => {
        t1.to(".anim__menu",  {
            x:0,
            y:0,
            borderRadius: 0,
            duration: 1,
            zIndex: 90,
            ease: "power4.inOut",
        })

        t1.from(
            ".anim__menu__list a",
            {
                y: 100,
                opacity: 0,
                ease: "power4.inOut",
                stagger: 0.1,
                delay: -0.1
            },
        );

        t1.reverse();
    }, [])

    return  (
        <>
            <nav className={`w-full p-8 flex items-start ${route==="/"?'justify-end':'justify-between'} md:absolute top-0 z-[91]`}>
                {route === "/"?null:<a href="/" className='button__hover__difference block btn-menu cursor-none text-xl z-[91]'>Home</a>}
                <button className='button__hover__difference btn-menu cursor-none text-xl z-[91]' onClick={toggle}>Menu</button>
            </nav>
            <div className={`anim__menu grid place-items-center fixed w-full translate-x-full -translate-y-full ml-auto h-full rounded-full rounded-tr-none -z-[11] top-0 left-0 bg-black`}>
                <div className='anim__menu__list flex flex-col w-full items-center justify-center'>
                    {
                        navigation.map(link => {
                            return <a className="button__hover__difference text-7xl my-8" onClick={toggle} href={link.href}>{link.title}</a>
                        })
                    }
                </div>
            </div>
        </>
    )
}