import React from "react"
import { Formik, Form } from "formik";
import { sendContactForm } from "../utils/contactService";
import * as Yup from "yup";
import Input from "./Input";

const schema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Your name must be at least 2 characters long.")
        .max(20, "Your name cannot exceed 20 characters.")
        .required("Name is required."),
    email: Yup.string().email("Invalid email.").required("Email is required."),
    subject: Yup.string()
        .min(2, "Subject must be at least 2 characters long.")
        .max(20, "Subject cannot exceed 20 characters.")
        .required("Subject is required."),
});


function ContactForm({ className = "flex flex-col items-center w-full md:mx-auto lg:items-start" }) {
    return (
        <div className='w-full'>
            <Formik
                initialValues={{ name: "", email: "", subject: "", message: "" }}
                validationSchema={schema}
                onSubmit={
                    (values, actions) => {
                        sendContactForm(values)
                            .then(() => {
                                actions.resetForm()
                            })
                            .catch((err) => {
                            })
                            .finally(() => actions.setSubmitting(false))
                    }}
            >
                {({ isSubmitting, errors, touched }) => (
                    <Form
                        name="contact"
                        netlify="true"
                        data-netlify={true}
                        className={className}
                    >
                        {errors.name && touched.name ? (
                            <p className="text-lg text-red-500" name="name" component="div" >{errors.name}</p>
                        ) : null}
                        {errors.email && touched.email ? (
                            <p className="text-lg text-red-500" name="name" component="div" >{errors.email}</p>
                        ) : null}
                        {errors.subject && touched.subject ? (
                            <p className="text-lg text-red-500" name="name" component="div" >{errors.subject}</p>
                        ) : null}
                        <Input type="hidden" name="bot-field" />
                        <div className="flex flex-col w-full lg:flex-row">
                            <Input className="lg:mr-4" type="text" name="name" placeholder="Name" autocomplete="off" />
                            <Input className="lg:ml-4" type="email" name="email" placeholder="Email" />
                        </div>
                        <Input type="text" name="subject" placeholder="Subject" />
                        <Input type="text" component="textarea" name="message" placeholder="Message" />
                        <button type="submit" className="cursor-none button__hover__difference inline-block mt-4 text-white text-xl px-12 py-4 bg-brand" disabled={isSubmitting}>Send Message</button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ContactForm