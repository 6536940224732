import { techstack } from "../utils/techstack";

export default function TechStack(){
    return <section id="technologies">
        <div className='page__width my-[20%]'>
            <h2 className="text-5xl text-center mb-4">Tech Stack</h2>
            <p className='text-xl lg:text-2xl text-text text-center mb-4'>What technologies I use to create branded, custom-coded websites:</p>
            <div className="anim__technologies flex flex-wrap items-center justify-between">
                {techstack.map(technology => {
                    return <div className="w-1/2 lg:w-1/4 mx-auto py-10 lg:p-20">
                        {technology}
                    </div>
                })}
            </div>
        </div>
    </section>
}