import mg12 from '../assets/img/portfolio-mg12.png'
import evo from '../assets/img/portfolio-evo.png'
import adnova from '../assets/img/portfolio-adnova.png'
import smoothaffairs from '../assets/img/portfolio-smoothaffairs.jpg'
import connectprint from '../assets/img/portfolio-cp.png'
import premiumservices from '../assets/img/portfolio-ps.jpg'

const portfolio = [
    {
        title: "Adnova",
        href: "https://adnova.vercel.app",
        imageSrc: adnova
    },
    {
        title: "Premium Services",
        href: "https://premiumservices.netlify.app/",
        imageSrc: premiumservices
    },
    {
        title: "Smooth Affairs",
        href: "https://smoothaffairs.netlify.app/",
        imageSrc: smoothaffairs,
    },
    {
        title: "ConnectPrint",
        href: "https://connectprint.net",
        imageSrc: connectprint
    },
    {
        title: "Mgnesium12",
        href: "https://mgnesium12.com",
        imageSrc: mg12
    },
    {
        title: "Ivaylo Ivanov Portfolio",
        href: "http://storyou.bg",
        imageSrc: evo
    }
]

export default portfolio;