import { ReactComponent as ArrowLink } from '../assets/icons/arrow-link.svg';

export default function PortfolioRow({ href, title, imageSrc, imageAlt }) {
    return <div className='anim__collapse w-full z[5] my-8 lg:my-0'>
        <div className="border-b border-text">
            <div className='w-full anim__reveal relative cursor-none button__hover__difference z-[8] border-text flex justify-between items-center'>
                <a href={href} target="_blank" className='w-full flex items-center justify-between cursor-none text-4xl md:text-7xl py-8 text-start'>{title}
                    <ArrowLink className='w-8 h-8 mr-4' />
                </a>
                <img className="anim__reveal__img absolute top-0 left-0 w-80 h-80 rounded-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-0 pointer-events-none z-[195]" src={imageSrc} alt={imageAlt} />
            </div>
        </div>
    </div>
}
