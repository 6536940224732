import ContactForm from "../components/ContactForm";

export default function Contact(){
    return (
        <>
            <div className="w-full flex flex-col justify-center page__width md:min-h-[100vh] items-center">
                <div className='w-full lg:w-4/5'>
                    <h1 className='text-center text-6xl lg:text-8xl'>Let's create something beautiful together</h1>
                    <p className='text-center text-text text-lg my-4 lg:mb-16'>Get in touch with me by using the form below or by sending me an email at <a href="mailto:tirottawebservices@gmail.com" className='button__hover__difference text-brand link__hover'>tirottawebservices@gmail.com</a></p>
                    <ContactForm />
                </div>
            </div>
        </>
    )
}