import hero from "../assets/img/about.png";
import ImageWrapper from "../components/ImageWrapper";
import Spinner from "../components/Spinner";
import TechStack from "../sections/TechStack";

export default function About() {
    return (
        <>
            <div className="w-full flex min-h-[75vh] md:min-h-[100vh] items-center justify-center">
                <div className='opacity-50 absolute w-1/2 lg:w-1/5'>
                    <ImageWrapper src={hero} alt="Hero" className="-z-20 w-full object-cover" />
                    <h2 className='hidden lg:block lg:text-[12rem] absolute text-[#181818] -z-50 bottom-0 left-[50%]'>About</h2>
                </div>
                <h1 className='text-8xl lg:text-[12rem] text-center relative'>About</h1>
                <Spinner />
            </div>
            <section id="about">
                <div className='page__width my-[20%]'>
                    <p className='gsap__reveal text-3xl text-[#CCC] my-8'>Hello, my name is Cristian Tirotta. I am a web designer and developer with over 5 years of experience with creating unique & converting websites for various businesses, including eCommerce stores, portfolios, event pages & more.</p>
                    <p className='gsap__reveal text-3xl text-[#CCC] my-8'>My ultimate goal is to help businesses achieve online success by creating fully-customized websites that align with their unique brand identity. By custom-coding each website and crafting visually captivating designs, I ensure a perfect balance between functionality and aesthetics.</p>
                </div>
            </section>
            <TechStack />
        </>
    )
}