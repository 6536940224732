import React from 'react'

export default function NotFound() {
    return (
        <div className="w-full flex flex-col md:min-h-[100vh] items-center justify-center">
            <h2 className='text-8xl text-center'>Something's not right</h2>
            <p className='text-2xl text-text my-4'>The page you're trying to load does not exist</p>
            <a href="/" className='button__hover__difference link__hover scale__cursor block text-brand text-2xl md:text-3xl'>Let's take you back home</a>
        </div>
    )
}