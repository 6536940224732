import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { flushSync } from 'react-dom'
import { DotButton, useDotButton } from './EmblaCarouselDotButton';

const TWEEN_FACTOR = 4.2

const numberWithinRange = (number, min, max) =>
    Math.min(Math.max(number, min), max)

const EmblaCarousel = (props) => {
    const slides = props.slides;
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
    const [tweenValues, setTweenValues] = useState([])

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);
    useEffect(() => {
        if (emblaApi) {
            console.log(emblaApi.slideNodes()); // Access API
        }
    }, [emblaApi]);
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);

    const onScroll = useCallback(() => {
        if (!emblaApi) return

        const engine = emblaApi.internalEngine()
        const scrollProgress = emblaApi.scrollProgress()

        const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
            let diffToTarget = scrollSnap - scrollProgress

            if (engine.options.loop) {
                engine.slideLooper.loopPoints.forEach((loopItem) => {
                    const target = loopItem.target()
                    if (index === loopItem.index && target !== 0) {
                        const sign = Math.sign(target)
                        if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress)
                        if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress)
                    }
                })
            }
            const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR)
            return numberWithinRange(tweenValue, 0, 1)
        })
        setTweenValues(styles)
    }, [emblaApi, setTweenValues])

    useEffect(() => {
        if (!emblaApi) return

        onScroll()
        emblaApi.on('scroll', () => {
            flushSync(() => onScroll())
        })
        emblaApi.on('reInit', onScroll)
    }, [emblaApi, onScroll])

    return (
        <div className="embla bg-[#121212] flex flex-col items-center">
            <div className="embla__viewport overflow-hidden" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((slide, index) => (
                        <div
                            className="embla__slide"
                            key={index}
                            style={{
                                ...(tweenValues.length && { opacity: tweenValues[index] }),
                            }}
                        >
                            <div className='flex flex-col items-center w-3/5 mx-auto lg:w-full text-center'>
                                <p className='text-2xl lg:text-3xl text-white mb-4 lg:w-9/12'>{slide.comment}</p>
                                <p className='text-xl lg:text-2xl text-text'>{slide.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="embla__dots">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={'embla__dot scale_cursor cursor-none'.concat(
                            index === selectedIndex
                                ? ' embla__dot--selected'
                                : ''
                        )}
                    />
                ))}
            </div>
        </div>
    )
}

export default EmblaCarousel
