import Menu from "./Menu";
import Footer from "./Footer";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger)
export default function Layout({ children }) {
    let mm = gsap.matchMedia();
    useEffect(() => {
        gsap.utils.toArray(".anim__reveal").forEach((el) => {
            const image = el.querySelector(".anim__reveal__img"),
                setX = gsap.quickTo(image, "x", { duration: 0.6, ease: "power3" }),
                setY = gsap.quickTo(image, "y", { duration: 0.6, ease: "power3" }),
                align = (e) => {
                    const top = el.getBoundingClientRect().top;
                    setX(e.clientX);
                    setY(e.clientY - top);
                },
                startFollow = () => document.addEventListener("mousemove", align),
                stopFollow = () => document.removeEventListener("mousemove", align),
                fade = gsap.to(image, {
                    autoAlpha: 1,
                    ease: "none",
                    paused: true,
                    onReverseComplete: stopFollow
                });

            mm.add("(min-width: 800px)", () => {
                el.addEventListener("mouseenter", (e) => {
                    fade.play();
                    startFollow();
                    align(e);
                });
            })

            el.addEventListener("mouseleave", () => fade.reverse());
        });

        let mouseCursor = document.querySelector(".cursor__difference");
        let btn = document.querySelectorAll(".button__hover__difference");

        window.addEventListener("mousemove", cursor);

        function cursor(e) {
            mm.add("(min-width: 800px)", () => {
                gsap.to(mouseCursor, {
                    x: e.clientX,
                    y: e.clientY,
                    duration: 0.2
                });
            })
        }

        btn.forEach(link => {
            link.addEventListener("mouseover", () => {
                mouseCursor.classList.add("scale-cursor");
                gsap.to(mouseCursor, {
                    scale: 2
                });
            });

            link.addEventListener("mouseleave", () => {
                mouseCursor.classList.remove("scale-cursor");
                gsap.to(mouseCursor, {
                    scale: 1
                });
            });
        });

        new SplitType(".gsap__reveal", {
            types: "words"
        })

        const sections = gsap.utils.toArray("section");

        sections.forEach((section, index) => {
            gsap.from(section, {
                yPercent: 50,
                opacity: 0,
                scrollTrigger: {
                    trigger: section,
                }
            })
        })

        gsap.from('.anim__collapse button', {
            yPercent: 33,
            opacity: 0,
            stagger: 0.25,
            scrollTrigger: {
                trigger: "#services"
            }
        })

        gsap.from('.anim__technologies div', {
            xPercent: 33,
            opacity: 0,
            stagger: 0.25,
            scrollTrigger: {
                trigger: "#technologies"
            }
        })

        gsap.from('.services__left', {
            opacity: 0,
            scrollTrigger: {
                trigger: "#services"
            }
        })

        gsap.from('.word', {
            yPercent: 100,
            opacity: 0,
            stagger: 0.075,
            duration: 1.25
        })
    }, [])

    const location = useLocation()

    return (
        <>
            <div className="hidden md:block cursor__difference w-10 h-10 border-2 border-white rounded-full fixed pointer-events-none bg-white -translate-y-1/2 -translate-x-1/2 mix-blend-difference z-[999] origin-[100%_100%]"></div>
            <Menu route={location.pathname} />
            {children}
            <section id="footer">
                {location.pathname === "/contact" ? null : <Footer />}
            </section>
        </>
    )
}