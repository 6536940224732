const testimonials = [
    {
        name: "Valeri Tafrov, Founder of PremiumServices",
        comment: "We were in great need for a redesign of our website and I am more than happy with the results. Our company is now way more trustworthy and exceptional in the digital space."
    },
    {
        name: "Dawid Dzikowski, CEO of GoodyGo",
        comment: "I am very grateful to Cristian for creating a beautiful website for my company, I've seen great results since then and remained in close contact with him thanks to his professionalism."
    },
    {
        name: "Kristian Subev, Founder of Mgnesium12",
        comment: "The communication with Cristian, the dedication and the overall attention to the project are just on another level. Don't waste your money on agencies, because they can't give you even half of the attention Cristian gave me! Recommended!"
    },
    {
        name: "Alex Nguen, Founder of ChiStrength",
        comment: "I highly recommend Cristian for his exceptional web development skills. His attention to detail, technical expertise, and commitment to delivering high-quality results surpassed my expectations. Working with Cristian has greatly improved my online presence and positively impacted my business."
    },
    {
        name: "Salvatore Maniscalco, Veteran Italian Chef",
        comment: "Couldn't ask for more, Cristian did an amazing job with the brand strategy, design and development of the website. Worth every penny!"
    },
    {
        name: "Ivaylo Ivanov, Videographer",
        comment: "Really satisfied with Cristian's understanding of requirements and his dedication to go for that extra mile. I recommend his services to anyone in need."
    },
]

export default testimonials;