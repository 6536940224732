const services = [
    {
        id: 1,
        title: "UI & UX Design",
        description: "Using a strategy-first approach, I study your business model and elevate your brand with stunning UI/UX design that creates unforgettable customer journeys and sets you apart."
    },
    {
        id: 2,
        title: "Website Development",
        description: "No templates. No page builders. Just bespoke, custom-coded websites crafted to perfection, offering flawless functionality, security, and online presence."
    },
    {
        id: 3,
        title: "Paid Advertising",
        description: "Using a proven structure and growth-oriented strategy, I design precision-targeted ad campaigns that drive conversions and amplify your brand’s revenue."
    },
    {
        id: 4,
        title: "Fundamental SEO Package",
        description: "I ensure your website has all of the basic SEO functionalities and best practices set in place in order for your business to attract high-quality traffic."
    },
]

export default services;