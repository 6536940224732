import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom"
import Home from './routes/Home'
import About from "./routes/About";
import Portfolio from "./routes/Portfolio";
import Contact from "./routes/Contact";
import AnimationLayout from "./AnimationLayout";
import NotFound from './routes/NotFound';
import Lenis from '@studio-freight/lenis';

export default function App() {
    useEffect(() => {
        const lenis = new Lenis({
            duration: 2
        })
        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }
        requestAnimationFrame(raf)
    }, [])
    return (
        <Routes>
            <Route element={<AnimationLayout />}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}
