import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './globals.css'
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Layout>
            <App />
        </Layout>
    </BrowserRouter>
);
