import ImageWrapper from "../components/ImageWrapper";
import hero from "../assets/img/portfolio.png";
import Spinner from "../components/Spinner";
import PortfolioSection from "../sections/PortfolioSection";

export default function Portfolio(){
    return(
        <>
            <div className="w-full flex min-h-[75vh] md:min-h-[100vh] items-center justify-center">
                <div className='opacity-50 absolute w-1/2 lg:w-1/5'>
                    <ImageWrapper src={hero} alt="Hero" className="-z-20 w-full object-cover" />
                    <h2 className='hidden lg:block lg:text-[12rem] absolute text-[#181818] -z-50 bottom-0 left-[50%]'>Portfolio</h2>
                </div>
                <h1 className='text-8xl lg:text-[12rem] text-center relative'>Portfolio</h1>
                <Spinner />
            </div>
            <PortfolioSection />
        </>
        )
}