export default function Footer(){
    return <footer>
        <div className='page__width flex flex-col py-[10%] items-center'>
            <h2 className='text-5xl lg:text-8xl text-center my-8'>Let's start a project <span className='block text-[inherit]'>together!</span></h2>
            <p className='text-text text-2xl'>Contact me at</p>
            <a href="mailto:tirottawebservices@gmail.com" className='button__hover__difference link__hover text-brand text-2xl'>tirottawebservices@gmail.com</a>
            <p className='text-text text-2xl'>OR</p>
            <a href="https://wa.me/+359878112269" className='button__hover__difference link__hover link__hover_green text-[#25d366] text-2xl'>Reach out on WhatsApp</a>
        </div>
        <p className='text-text text-center text-lg my-2 font-light'>&copy;Cristian Tirotta | All Rights Reserved.</p>
    </footer>
}